import React,{ useState } from 'react'
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { firebaseurl } from '../../raw/data';

const initialVal = {
    name:"",
    email:"",
    message:""
};

const Contact = () => {
    const [user, setUser] = useState(initialVal);
    const { name, email, message } = user;
    const setValue = (e) =>{
        setUser({ ...user,[e.target.name]:e.target.value });
    }
    const submitNow = async(e) =>{
        e.preventDefault();
        const response = await fetch(firebaseurl.url, {
            method: 'POST',
            mode: 'cors', 
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, email, message })
          });
        if(response){
            toast.success("Submit Success", {
                position: toast.POSITION.TOP_LEFT,
            });
        }else{
            toast.error("Submit Error", {
                position: toast.POSITION.TOP_LEFT,
            });
        }
    }

    return (
        <div>
            <ToastContainer />
            <h1>Contact <span>With Us</span></h1>
            <div className="contact_container">
                <div>
                    <h2>Get In Touch</h2>
                    <p className="h3">“A dream you dream alone is only a dream. <br /> A dream you dream together is reality.”</p>
                    <div>
                        <div className="contact_container_child">
                            <CallIcon className="icons"/>
                            <div>
                                <p className="heading">Phone</p>
                                <p>+91 8617611453</p>
                            </div>
                        </div>
                        <div className="contact_container_child">
                            <WhatsAppIcon className="icons"/>
                            <div>
                                <p className="heading">Whats App</p>
                                <p>+91 8617611453</p>
                            </div>
                        </div>
                        <div className="contact_container_child">
                            <MailIcon className="icons"/>
                            <div>
                                <p className="heading">Email</p>
                                <p> edsoftlabs@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact_container_form" id="contactus">
                    <span>
                        <input required name="name" type="text" placeholder="Your Name" onChange={setValue} value={user.name} />
                        <input required name="email" type="email" placeholder="Your Email" onChange={setValue} value={user.email} />
                    </span>
                    <textarea required name="message" placeholder="Your Message..." cols="30" rows="10" onChange={setValue} value={user.message} ></textarea>
                    <button onClick={submitNow} className="btn">Send Message</button>
                </div>
            </div>
        </div>
    )
}

export default Contact
