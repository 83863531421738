import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import MenuIcon from '@material-ui/icons/Menu';
// import { mylogo } from '../../raw/data';
import img from '../../images/logo.png';

const NavigationBar = () => {
    const [burger,setBurger] = useState(false);
    const [color,setColor] = useState(false);
    const chengeBgColor = () =>{
        if(window.scrollY >= 100){
            setColor(true);
        }else{
            setColor(false);
        }
    }
    window.addEventListener("scroll",chengeBgColor);

    return (
        <div className={color?"nav_container active":"nav_container"}>
            <img src={img} alt="logo" width="70" height="70"/>
            <ul className={burger ? "open" : ""}>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/'>Services</Link></li>
                <li><a href='#about'>About Us</a></li>
                <li><a href='#myprojects'>Our Cources</a></li>
                <li><a href='#contactus'>Contact Us</a></li>
            </ul>
            {/* <a className='btn' href='tel:+918617611453'><span><AddIcCallIcon/></span>Tel: +91 8617611453</a> */}
            <div onClick={()=>setBurger(!burger)} className="hamburger"> <MenuIcon /> </div>
        </div>
    )
}

export default NavigationBar
