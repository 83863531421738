import React from 'react'
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


const Card = (props) => {
    return (
        <div className="header_card hover_card">
            <h1>{props.heading}</h1>
            <p className="catagory">{props.catagory}</p>
            <div className="img_round">
                <img src={props.img1} alt="" />
                <img src={props.img2} alt="" />
                <img src={props.img3} alt="" />
            </div>
            <p className="des">{props.des}</p>
            <Link to="/">Read More<span><ArrowForwardIcon/></span></Link>
        </div>
    )
}

export default Card
