// LATEST WORKING PROJECTS section

export const portfolioData = [
    {
           images: "/images/svg/laravel.svg",
           doc: "3 Month Cource",
           title: " LARAVEL ",
        // readmoreLink:" https://github.com/DibyenduGhoshDG"
    },
    {
           images: "/images/svg/flutter.svg",
           doc: "3 Month Cource",
           title: "FLUTTER",
        // readmoreLink:" https://github.com/DibyenduGhoshDG"
    },
    {
           images: "/images/svg/react.svg",
           doc: "3 Month Cource",
           title: "REACT JS",
        //    readmoreLink:" https://github.com/DibyenduGhoshDG"
    },
    {
           images: "/images/svg/angular.svg",
           doc: "3 Month Cource",
           title: "ANGULAR",
        // readmoreLink:" https://github.com/DibyenduGhoshDG"
    }
];

// firebase database links

export const firebaseurl={
    url: "https://reactcontact-d5492-default-rtdb.firebaseio.com/contactform.json",
};

// nav logo your brand

export const mylogo = {
    logo: "./images/logo.png"
};

export const socialLinks = {
    // facebook:"https://github.com/DibyenduGhoshDG",
    // linkedin:"https://github.com/DibyenduGhoshDG",
    // twitter:"https://github.com/DibyenduGhoshDG",
    // youtube:"https://github.com/DibyenduGhoshDG"
};