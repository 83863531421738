import React from "react";
import Header from "../../component/Header/Header";
import Portfolio from '../../component/Portfolio/Portfolio';
import Card from "../../component/Cards/Card";
import creative from "../../images/creative.png";
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import YouTubeIcon from '@material-ui/icons/YouTube';
import agency from '../../images/agency.png';
import Contact from "../../component/ContactUs/Contact";
import Footer from "../../component/Footer/Footer";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


const Home = () => {
  return (
    <>
      <div className="header_main" id="home" >
        <Header />
      </div>
      <div className="header_service" id="about">
        <div className="text">
          <div className="sctext">What We Do</div>
          <p> 
            We think big and have hands in all leading technology platforms to
            provide <br /> you wide array of services.
          </p>
        </div>
        <div className="header_card_container">
          <Card
            heading="Offer Cources"
            // catagory="UI/UX | PHP | JAVA "
            // img1="/images/svg/php.svg"
            // img2="/images/svg/ux.svg"
            // img3="/images/svg/tablet.svg"
            des="At first You must be enroll for a cource you want to do."
          />
          <Card
            heading="Offer Internships"
            // catagory="IPHONE | ANDROID | CROSS PLATFORM"
            // img1="/images/svg/apple.svg"
            // img2="/images/svg/java.svg"
            // img3="/images/svg/android.svg"
            des=" After completing the 3 months cource you can able to apply for a Internship or Job."
          />
          <Card 
            heading="Offer Jobs"
            // img1="/images/svg/laravel.svg"
            //  catagory="MAGENTO | SHOPIFY | WOO-COMMERCE"
            //  img2="/images/svg/wordpress.svg"
            //  img3="/images/svg/tablet.svg"
            des="After completing the 3 months cource you can able to apply for a Internship or Job."
        /> 
        </div>
      </div>
      <div className="header_call">
          <div>
            <img src={creative} alt="" />
          </div>
           <div> 
            <h1>For More Details</h1>
            <p>Send Message Or call us now <span><PhoneCallbackIcon/></span> +91 8617611453</p>
          </div> 
      </div>
      <div className="header_portfolio" id="myprojects">
         <h1> Introducing Our Cources</h1>
         {/* <p className="sub">WORKING PROCESS</p>  */}
        <div className="portfolio_container">
          <Portfolio/>
        </div>
        <div className="load_more">
          <Link to="/">Load More<span><ArrowForwardIcon/></span></Link> 
        </div>
      </div>
       {/* <div className="header_youtube">  
          <h1><a href="https://www.youtube.com/channel/UCszwffHqxSzl-Iq1gI0N0uA"><span><YouTubeIcon style={{fontSize:"50px"}}/></span>DG TechyBusiness</a></h1>
          <img src={agency} alt="" />
          <div className="youtube_container">
            <iframe className="header_sec_video" width="420" height="315" title="myFrame1"
              src="https://www.youtube.com/embed/tMqhrI08klY">
            </iframe>
            <iframe width="420" height="315" title="myFrame2"
              src="https://www.youtube.com/embed/CAK4VjmdIOw">
            </iframe>
            <iframe className="header_sec_video" width="420" height="315" title="myFrame3"
              src="https://www.youtube.com/embed/5KxLI_64fnE">
            </iframe>
        </div>
        </div>*/}
      <div className="header_contact">
        <Contact/>
      </div>
      <Footer/>
    </>
  );
};

export default Home;
