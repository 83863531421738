import React from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import img2 from "../../images/img2.png";

const Header = () => {
  return (
    <div className="header_container">
      <div>
        {/* The digital revolution, Help business to grow, Help students to achieve their goal. */}
        <h1>
          {" "}
          The <span> Digital </span> Revolution{" "}
        </h1>
        <p>
          "Help business to grow and Help students to achieve their goal through
          software. Building a network for the smartest people."
        </p>
        {/* <Link className='btn' to='/myportfolio'><span><NavigateNextIcon/></span>GET MY RESUME</Link> */}
      </div>
      <div>
        <img src={img2} alt="imags" />
      </div>
    </div>
  );
};

export default Header;
