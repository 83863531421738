import React from 'react'
import { BrowserRouter as Router,Route,Switch } from 'react-router-dom';
import NavigationBar from './component/Navigation/NavigationBar';
import Home from './pages/Home/Home';
import Portfoliopage from './pages/Portfolio/Portfoliopage';
import Projects from './pages/Services/Projects';


const App = () => {
    return (
       <div className="main_container">
            <Router>
                <NavigationBar/>
                <Switch>
                    <Route path='/' component={Home} exact/>
                    <Route path='/myportfolio' component={Portfoliopage} exact/>
                    <Route path='/projects' component={Projects} exact/>
                </Switch>
            </Router>
       </div>
    )
}

export default App
