import React from 'react'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { portfolioData } from '../../raw/data';

const Portfolio = () => {
    return (
        <>
        { 
            portfolioData.map(setdb=>(
                <div key={setdb.title} className="box" style={{marginBottom:"2rem"}}>
                    <div>          
                        <img src={setdb.images} alt="course image"/>        
                    </div>
                    <div className="text">
                        <p className="date">{setdb.doc}</p>
                        <a className="main_link" href={setdb.readmoreLink}>{setdb.title}</a>
                        <a className="btn_link" href={setdb.readmoreLink}> <ArrowForwardIcon/> <span>Read More</span> </a>
                    </div>
                </div>
            ))
        }
        </>
    )
}

export default Portfolio;