import React from 'react'
import { socialLinks } from '../../raw/data';

const Footer = () => {
    return (
        <div className="footer_container">
            <div>
                <p>© Copyright 2022 All Rights Reserved. MADE IN INDIA </p>
            </div>
            <div>
                <ul>
                    <li><a href="https://www.facebook.com/Edsoftlab-102603245835696">FACEBOOK</a></li>
                    <li><a href={socialLinks.linkedin}>LINKED IN</a></li>
                    <li><a href="https://twitter.com/EdsoftL">TWITTER</a></li>
                    <li><a href="https://www.instagram.com/edsoftlab/">INSTAGRAM</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Footer
 